.slick-slide > div {
  margin: 0 10px;
  height: 100%;
}
.slick-list {
  margin: 0 -10px;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto !important;
  padding: 8px 0;
}

.slick-dots li.slick-active button:before {
  color: #018781;
}
