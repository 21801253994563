@media screen and (max-width: 865px) {
  .fc-toolbar {
    flex-direction: column;
  }
}

.fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
  cursor: pointer;
}

.fc .fc-button-primary {
  background-color: #018781 !important;
  border-color: #018781 !important;
}

.fc-timeGridWeek-button {
  background-color: #018781 !important;
  border-color: #005e5a !important;
}

.fc-dayGridMonth-button {
  background-color: #018781 !important;
  border-color: #005e5a !important;
}

.fc-listWeek-button {
  background-color: #018781 !important;
  border-color: #005e5a !important;
}

.fc-prev-button {
  background-color: #018781 !important;
  border-color: #005e5a !important;
}

.fc-next-button {
  background-color: #018781 !important;
  border-color: #005e5a !important;
}

.fc-dayGridMonth-button .fc-button .fc-button-primary {
  background-color: #018781 !important;
}

.fc-button-primary:active {
  background-color: #005e5a !important;
  border-color: #005e5a !important;
}

.fc-button-primary:hover {
  background-color: #005e5a !important;
  border-color: #005e5a !important;
}

.fc-today-button {
  background: #018781 !important;
  border: 1px solid #018781 !important;
}

.fc-day-grid-event > .fc-content {
  white-space: normal !important;
}

.dc-text {
  box-shadow: 0px 3px 6px #c7c7c729 !important;
  padding: 8px 16px 8px 8px !important;
  margin-bottom: 0 !important;
}

.icon-person {
  font-size: 1.25rem !important;
  position: relative !important;
  top: 3px !important;
  color: #005e5a !important;
  width: 36px !important;
}

.div-dialog {
  display: flex;
  margin-bottom: 6px;
  justify-content: space-between;
}

.div-dialog > .dialog-text {
  display: flex;
  align-self: center;
}

.div-dialog > .dialog-text > .dialog-icon {
  font-size: 1rem;
  width: 36px;
  display: flex;
  justify-content: center;
  color: #018781;
}

.div-dialog > .dialog-text > .dialog-icon-muted {
  margin-left: 4px;
  color: #bdbdbd;
  font-size: 1rem;
}

.dialog-text-capitalize {
  display: flex;
  margin-left: 2px;
  align-self: center;
  text-transform: capitalize;
  margin-bottom: 6px !important;
}

.dialog-text-capitalize > .dialog-icon {
  font-size: 1rem;
  width: 36px;
  color: #018781;
}

.dialog-text-2 {
  display: flex;
  align-self: center;
  margin-bottom: 6px !important;
}

.dialog-text-2 > .div-dialog-text {
  width: 36px;
  display: flex;
  letter-spacing: -1px;
  justify-content: center;
}

.dialog-text-2 > .dialog-icon {
  width: 36px;
  font-size: 1rem;
  color: #018781;
}

.dialog-text-2 > .div-dialog-text > .div-dialog-text-2 {
  color: #018781;
  font-weight: 600;
}

.dialog-text-area {
  margin-bottom: 0 !important;
}

.dialog-text-area > .dialog-text {
  display: flex;
  align-self: center;
  padding: 16px;
  padding-left: 8px;
}

.dialog-text-area > .dialog-text > .dialog-icon {
  width: 36px;
  font-size: 1rem;
  color: #018781;
}
